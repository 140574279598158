<template>
  <v-container>
    <h1>Colors</h1>
    <v-row>
      <v-col cols="12">
        <v-data-table
          color="primary"
          :hide-default-footer="true"
          :multi-sort="true"
          :sort-by="['dark', 'name']"
          :headers="headers"
          :items="colors"
          :items-per-page="itemsPerPage"
          item-key="id"
        >
          <template v-slot:[`item.dark`]="{ item }">
            <v-simple-checkbox v-model="item.dark" disabled></v-simple-checkbox>
          </template>
          <template v-slot:[`item.colorcode`]="{ item }">
            <v-btn class="button" text outlined :color="item.code">
              <v-icon>mdi-home</v-icon>{{ item.name }}
            </v-btn>
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <v-btn class="button" text outlined @click="onEdit(item)">
              <v-icon>mdi-pencil</v-icon>Edit
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="12" lg="12" md="12" sm="12">
        <v-card height="425" width="100%" class="light">
          <v-card-text>
            <v-row>
              <v-app-bar color="primary">
                <v-app-bar-nav-icon></v-app-bar-nav-icon>
                <v-divider class="mx-4" vertical></v-divider>
                <div>
                  <a>
                    <v-img src="@/assets/nepomuk.png" height="48" width="48">
                    </v-img>
                  </a>
                </div>
                <v-divider class="mx-4" vertical></v-divider>
                <v-toolbar-title class="company">Theme</v-toolbar-title>
                <v-divider class="mx-4" vertical></v-divider>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn text> <v-icon>mdi-home</v-icon>Home </v-btn>
                  <v-btn text>
                    <v-icon>mdi-account-box-outline</v-icon>Contact Us
                  </v-btn>
                </v-toolbar-items>
              </v-app-bar>
            </v-row>
            <v-row>
              <v-card class="light-background" width="100%">
                <v-row>
                  <v-col cols="12" xl="2" lg="2" md="3" sm="4">
                    <v-btn class="button" text outlined color="primary">
                      <v-icon>mdi-home</v-icon>Primary</v-btn
                    >
                  </v-col>
                  <v-col cols="12" xl="2" lg="2" md="3" sm="4">
                    <v-btn class="button" text outlined color="secondary">
                      <v-icon>mdi-home</v-icon>Secondary</v-btn
                    >
                  </v-col>
                  <v-col cols="12" xl="2" lg="2" md="3" sm="4">
                    <v-btn class="button" text outlined color="accent">
                      <v-icon>mdi-home</v-icon>Accent</v-btn
                    >
                  </v-col>
                  <v-col cols="12" xl="2" lg="2" md="3" sm="4">
                    <v-btn class="button" text outlined color="error">
                      <v-icon>mdi-home</v-icon>Error</v-btn
                    >
                  </v-col>
                  <v-col cols="12" xl="2" lg="2" md="3" sm="4">
                    <v-btn class="button" text outlined color="info">
                      <v-icon>mdi-home</v-icon>Info</v-btn
                    >
                  </v-col>
                  <v-col cols="12" xl="2" lg="2" md="3" sm="4">
                    <v-btn class="button" text outlined color="menu">
                      <v-icon>mdi-home</v-icon>Menu</v-btn
                    >
                  </v-col>
                  <v-col cols="12" xl="2" lg="2" md="3" sm="4">
                    <v-btn class="button" text outlined color="success">
                      <v-icon>mdi-home</v-icon>Success</v-btn
                    >
                  </v-col>
                  <v-col cols="12" xl="2" lg="2" md="3" sm="4">
                    <v-btn class="button" text outlined color="warning">
                      <v-icon>mdi-home</v-icon>Warning</v-btn
                    >
                  </v-col>
                  <v-col cols="12" xl="2" lg="2" md="3" sm="4">
                    <v-btn class="button" text outlined color="background">
                      <v-icon>mdi-home</v-icon>Background</v-btn
                    >
                  </v-col>
                  <v-col cols="12" xl="2" lg="2" md="3" sm="4">
                    <v-btn class="button" text outlined>
                      <v-icon>mdi-home</v-icon>Normal</v-btn
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xl="2" lg="2" md="3" sm="4">
                    <v-text-field color="primary" label="Primary" required />
                  </v-col>
                  <v-col cols="12" xl="2" lg="2" md="3" sm="4">
                    <v-text-field color="primary" label="Secondary" required />
                  </v-col>
                  <v-col cols="12" xl="2" lg="2" md="3" sm="4">
                    <v-text-field color="accent" label="Accent" required />
                  </v-col>
                  <v-col cols="12" xl="2" lg="2" md="3" sm="4">
                    <v-text-field color="error" label="Error" required />
                  </v-col>
                  <v-col cols="12" xl="2" lg="2" md="3" sm="4">
                    <v-text-field color="info" label="Info" required />
                  </v-col>
                  <v-col cols="12" xl="2" lg="2" md="3" sm="4">
                    <v-text-field color="menu" label="Menu" required />
                  </v-col>
                  <v-col cols="12" xl="2" lg="2" md="3" sm="4">
                    <v-text-field color="success" label="Success" required />
                  </v-col>
                  <v-col cols="12" xl="2" lg="2" md="3" sm="4">
                    <v-text-field color="warning" label="Warning" required />
                  </v-col>
                  <v-col cols="12" xl="2" lg="2" md="3" sm="4">
                    <v-text-field
                      color="background"
                      label="Background"
                      required
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-row>
            <v-row>
              <v-footer
                :color="lightPrimary"
                absolute
                app
                class="font-weight-medium"
              >
                <v-container class="container">
                  <v-row align="center" justify="center">
                    <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
                      <v-row align="center" justify="center">
                        <v-icon>mdi-copyright</v-icon> Application 2021 1.0.0
                      </v-row>
                    </v-col>
                    <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
                      <v-row align="center" justify="center">
                        <v-icon>mdi-facebook</v-icon>Social Icons
                      </v-row>
                    </v-col>
                    <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
                      <v-row align="center" justify="center">
                        <v-icon>mdi-sitemap</v-icon>
                        <div>Site Map</div>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-footer>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="showDialog" persistent width="400">
      <v-card>
        <v-card-title>Edit Color {{ selectedColor.name }}</v-card-title>
        <v-card-text>
          <v-row>
            <label>Dark</label>
            <v-simple-checkbox
              v-model="selectedColor.dark"
              disabled
            ></v-simple-checkbox>
          </v-row>
          <v-row>
            <v-color-picker
              dot-size="25"
              mode="hexa"
              show-swatches
              swatches-max-height="178"
              v-model='selectedColor.code'
            ></v-color-picker>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="onCancel"> Close </v-btn>
          <v-btn text @click="onSave"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import apiService from "@/_services/api-service";
import colorService from "@/_services/color-service";
import router from "@/router";
import store from "@/store";

export default {
  created() {
    this.get();
    this.lightPrimary = this.$vuetify.theme.themes.light.primary;
  },

  data: () => ({
    lightPrimary: "",
    colors: [],
    headers: [
      {
        text: "Id",
        align: "start",
        sortable: false,
        value: "id",
      },
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "Dark",
        align: "start",
        sortable: false,
        value: "dark",
      },
      {
        text: "Color",
        align: "start",
        sortable: false,
        value: "code",
      },
      {
        text: "Color Code",
        align: "start",
        sortable: false,
        value: "colorcode",
      },
      {
        text: "",
        filterable: false,
        sortable: false,
        value: "edit",
        width: 60,
      },
    ],
    itemsPerPage: 25,
    showDialog: false,
    originalColor: {},
    selectedColor: {},
  }),

  methods: {
    get() {
      apiService.get("color", this.getSuccess, this.getError);
    },

    getError() {},

    getSuccess(result) {
      this.colors = result.data;
    },

    onEdit(item) {
      console.log("Color: ", item);
      this.originalColor = item;
      this.selectedColor = {
        code: item.code,
        dark: item.dark,
        id: item.id,
        name: item.name
      };
      this.showDialog = true;
    },

    onCancel() {
      let item = this.colors.find(c => c.id == this.originalColor.id)
      item.code = this.originalColor.code
      item.dark = this.originalColor.dark,
      item.id = this.originalColor.id,
      item.name = this.originalColor.name
      this.showDialog = false
    },

    onSave() {
      let item = this.colors.find(c => c.id == this.originalColor.id)
      item.code = this.selectedColor.code
      item.dark = this.selectedColor.dark,
      item.id = this.selectedColor.id,
      item.name = this.selectedColor.name
      this.showDialog = false
      // colorService.setColor(this.$vuetify, this.selectedColor)
      colorService.put(this.selectedColor)
    },
  },

  router,
  store,
};
</script>

<style>
.button {
  margin: 10px;
}
.dark-background {
  background-color: var(--v-background-base) !important;
}
.light-background {
  background-color: var(--v-background-base) !important;
}
</style>